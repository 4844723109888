<template>
  <v-container fluid class="tw-p-6 tw-pt-20">
    <div class="loader"></div>
    <v-row>
      <v-col cols="12" md="8" class="!tw-p-0 tw-items-center tw-mb-4">
        <div
          class="tw-flex md:tw-items-center md:tw-flex-row tw-flex-col tw-w-full"
        >
          <div class="tw-flex tw-justify-between md:tw-block">
            <h1
              class="tw-text-3xl tw-font-bold tw-justify-self-start md:tw-mb-0 tw-mb-4"
            >
              Progetti ICA
            </h1>
            <div class="md:tw-hidden">
              <ProjectModal
                cta="Nuovo Progetto"
                :customers="customers"
                v-if="user.role == 'commerciale'"
              />
            </div>
          </div>
          <v-autocomplete
            class="md:!tw-ml-6 tw-rounded-xl"
            type="text"
            flat
            outlined
            background-color="white"
            clearable
            icon="mdi-magnify"
            :items="customers"
            v-model="selectedCustomer"
            :placeholder="'Filtra per cliente'"
            label="Filtra per cliente"
            hide-details=""
            item-text="name"
            return-object
            @change="getProjects"
          ></v-autocomplete>
          <v-text-field
            flat
            outlined
            type="text"
            icon="mdi-magnify"
            v-model="search"
            placeholder="Cerca nei progetti "
            class="md:!tw-ml-2 tw-rounded-xl"
            background-color="white"
            hide-details
            @input="saveSearch"
          ></v-text-field>
        </div>
      </v-col>
      <v-col
        col="12"
        md="4"
        class="no-pd tw-flex tw-justify-between tw-flex-row md:tw-justify-end md:tw-items-center tw-mb-4"
      >
        <div class="tw-text-sm tw-mr-2 !tw-flex-row tw-flex tw-justify-center">
          <div v-if="focus_mode">Focus Mode abilitata</div>
          <div v-else>Focus Mode disabilitata</div>
          <v-icon
            v-if="user.username == 'Fabiano' || user.username == 'Jacopo'"
            dark
            @click="toggleFocusMode"
            class="!tw-text-custom-blue tw-cursor-pointer hover:tw-opacity-60 tw-mr-4 tw-animate-pulse !tw-ml-2"
          >
            {{ focus_mode_icon }}
          </v-icon>
          <v-icon
            v-else
            dark
            class="!tw-text-custom-blue hover:tw-opacity-60 tw-mr-4 tw-animate-pulse !tw-ml-2"
          >
            {{ focus_mode_icon }}
          </v-icon>
        </div>

        <v-menu
          offset-y
          v-model="filter_menu"
          transition="slide-y-transition"
          :close-on-content-click="false"
          content-class="!tw-rounded-lg !tw-shadow-xl"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              color="#11797b"
              v-bind="attrs"
              v-on="on"
              style="margin-right: 10px"
              size="auto"
              >mdi-filter-menu</v-icon
            >
          </template>
          <v-card class="elevation-0 font2" style="padding: 20px">
            <div class="tw-text-lg tw-font-medium tw-mb-4">Filtri</div>
            <div
              v-if="focus_mode && user.role == 'master'"
              class="tw-text-m tw-font-medium tw-flex tw-items-center"
            >
              <v-icon color="orange" class="tw-mr-2" dark>mdi-alert</v-icon>
              <div>Filtri disabilitati quando la Focus Mode è abilitata</div>
            </div>
            <v-checkbox
              v-if="user.role == 'master'"
              v-model="filters.by_delegate"
              label="Visualizza solo i tuoi progetti"
              @change="setFilters"
              :disabled="focus_mode ? true : false"
            ></v-checkbox>
            <v-checkbox
              v-else
              v-model="filters.by_ref"
              label="Visualizza solo i tuoi progetti"
              @change="setFilters"
            ></v-checkbox>
          </v-card>
        </v-menu>
        <div class="md:tw-block tw-hidden">
        <ProjectModal
          cta="Nuovo"
          :customers="customers"
          :operators="['Jacopo','Marco']"
          v-if="user.role == 'commerciale'"
        />
        </div>
      </v-col>

      <v-skeleton-loader
        :loading="loading"
        transition="fade-transition"
        class="dt"
        height="500"
        type="table"
      >
        <v-data-table
          hide-default-footer
          :headers="headers"
          :items="filtered"
          loading="true"
          loading-text="Sto caricando..."
          :items-per-page="itemsPerPage"
          class="elevation-1 item dt font2 !tw-rounded-lg projects"
          @click:row="goTo"
          @page-count="pageCount = $event"
          :page.sync="page"
          :item-class="checkIfLocked"
        >
          <template v-slot:item.customer="{ item }">
            <td class="tw-uppercase" v-if="item.customer && item.customer.name">
              {{ item.customer.name }}
            </td>
          </template>

          <template v-slot:item.name="{ item }">
            <td class="tw-uppercase" v-if="item.name">
              {{ item.name }}
            </td>
          </template>

          <template v-slot:item.is_editing="{ item }">
            <v-chip
              color="transparent"
              class="capitalizer"
              v-if="item.is_editing"
            >
              {{ item.is_editing }}
            </v-chip>
          </template>

          <template v-slot:item.priority_status="{ item }">
            <v-chip color="transparent" v-if="item.last_status != 'completato'">
              {{ handlePriorityStatus(item.priority_status) }}
            </v-chip>
          </template>
          <template v-slot:item.last_request_timestamp="{ item }">
            <td>
              {{
                item.last_request_timestamp
                  ? moment(item.last_request_timestamp).format("DD/MM/YYYY")
                  : "Nessuna"
              }}
            </td>
          </template>

          <template v-slot:item.creation_timestamp="{ item }">
            <td>
              {{ moment(item.creation_timestamp).format("DD/MM/YYYY") }}
            </td>
          </template>

          <template v-slot:item.last_status="{ item }">
            <v-chip
              v-if="
                item.last_status != 'completato' &&
                item.status != 'in lavorazione' &&
                !item.missing_informations.are_missing
              "
              :color="getColor(item.last_status)"
              dark
              class="capitalizer"
            >
              {{ item.last_status }}
            </v-chip>
            <v-chip
              v-else-if="item.status == 'in lavorazione'"
              :color="'red'"
              dark
              class="capitalizer"
            >
              In Lavorazione
            </v-chip>
            <v-chip
              v-else-if="
                getQuotationStatus(item.preventivi_fustella).status ==
                'In Attesa di Prev'
              "
              color="green"
              dark
              class="capitalizer"
            >
              Completato
            </v-chip>
            <v-chip
              v-else-if="item.missing_informations.are_missing"
              color="lightgrey"
              dark
              class="capitalizer"
            >
              Info mancanti
            </v-chip>
            <v-chip v-else color="#4cb946" dark class="capitalizer">
              {{ item.last_status }}
            </v-chip>
          </template>

          <template v-slot:item.preventivi_fustella="{ item }">
            <v-tooltip v-if="item.quotations && item.quotations.filter(q => q.type == 'preventivi_fustella').length > 0" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  width="20px"
                  height="20px"
                  dark
                  class="bigger-badge elevation-0"
                  :color="_mapQuotationStatus(item).color"
                  >F</v-btn
                >
              </template>
              <span class="capitalizer">{{
                _mapQuotationStatus(item).label
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.preventivi_stampa="{ item }">
            <v-tooltip v-if="item.quotations && item.quotations.filter(q => q.type == 'preventivi_stampa').length > 0" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  width="20px"
                  height="20px"
                  dark
                  class="bigger-badge elevation-0"
                  :color="_mapQuotationStatus(item).color"
                  >IS</v-btn
                >
              </template>
              <span class="capitalizer">{{
                _mapQuotationStatus(item).label
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.production_status="{ item }">
            <v-tooltip v-if="item.production_status" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="getpStatus(item.production_status)"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  fab
                  class="elevation-0"
                  width="20px"
                  height="20px"
                >
                  C
                </v-btn>
              </template>
              <span class="capitalizer">{{ item.production_status }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.has_seen="{ item }">
            <v-chip :color="getSeen(item.has_seen)" dark class="capitalizer">
              {{ item.has_seen }}
            </v-chip>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <div class="text-center pt-2">
        <v-pagination
          color="#11797b"
          class="pag"
          v-model="page"
          :length="totalPages"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-row>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#11797b"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import InputField from "../components/ui/InputField.vue";
import { mapPriorityStatus } from "../plugins/utils";
import moment from "moment";
import "moment/locale/it";
import EventBus from "../plugins/eventBus";
import { mapQuotationStatus } from "../plugins/utils";
import ProjectModal from "../components/ProjectComponents/NewProjectModal/ProjectModal.vue";

moment.locale("it");

export default {
  components: { ProjectModal },
  name: "Projects",
  data() {
    return {
      selectedCustomer: null,
      customers: [],
      timeoutId: null,
      total_count: 0,
      loading: false,
      filters: {
        by_ref: false,
        by_delegate: false,
      },
      filter_menu: false,
      is_disabled: false,
      timeout: 7000,
      fab: false,
      page: 1,
      itemsPerPage: 100,
      pageCount: 0,
      scrollPosition: 0,
      search: "",
      headers: [
        { text: "ID", value: "id", sortable: false, filterable: false },
        {
          text: "Cliente",
          value: "customer",
          sortable: false,
          filterable: false,
        },
        {
          text: "Riferimento Progetto",
          value: "name",
          sortable: false,
          filterable: false,
        },
        {
          text: "Aperto da",
          value: "is_editing",
          sortable: false,
          filterable: false,
        },
        {
          text: "Progetto",
          value: "last_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Priorità",
          value: "priority_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Impianti Fustella",
          value: "preventivi_fustella",
          sortable: false,
          filterable: false,
        },
        {
          text: "Impianti Stampa",
          value: "preventivi_stampa",
          sortable: false,
          filterable: false,
        },
        {
          text: "Produzione",
          value: "production_status",
          sortable: false,
          filterable: false,
        },
        {
          text: "Corrispondente",
          value: "referrer",
          sortable: false,
          filterable: false,
        },
        {
          text: "Grafico",
          value: "delegate",
          sortable: false,
          filterable: false,
        },
        {
          text: "Ultima richiesta",
          value: "last_request_timestamp",
          sortable: false,
          filterable: false,
        },
        {
          text: "Data di creazione",
          value: "creation_timestamp",
          sortable: false,
          filterable: false,
        },
      ],
      pagination: {
        rowsPerPage: 50,
        descending: false,
        page: 1,
      },
    };
  },
  watch: {
    selectedCustomer(newV) {
      localStorage.setItem("selectedCustomer", JSON.stringify(newV));
    },
    projects() {
      if (this.timeoutId) {
        clearInterval(this.timeoutId);
      }
      this.timeoutId = setInterval(() => this.getProjects(), this.timeout);
    },
    page(newV, oldV) {
      if (newV != oldV) {
        this.loading = true;
        this.getProjects();
      }
    },
    search(newV, oldV) {
      let debounceTimer;
      clearTimeout(debounceTimer); // Clear the previous timer if it exists

      debounceTimer = setTimeout(() => {
        if (newV != oldV) {
          this.loading = true;
          this.getProjects();
        }
      }, 600); // Delay execution by 300 milliseconds
    },
  },
  created() {
    let selectedCustomer = null;
    try {
      selectedCustomer = JSON.parse(localStorage.getItem("selectedCustomer"));
    } catch (e) {
      selectedCustomer = null;
    }
    this.selectedCustomer = selectedCustomer;

    this.search = localStorage.getItem("projects_search");
  },
  mounted: function () {
    this.filters = this.user.filters || this.filters;

    if (this.user.role == "worker") {
      this.$router.push("/production").catch(() => {});
    }
    if (this.user.role == "magazzino") {
      this.$router.push("/stock-productions").catch(() => {});
    } else if (this.user.role == "contabile") {
      this.$router.push("/payments").catch(() => {});
    } else if (this.user.role == "esterno") {
      this.$router.push("/projects-queue").catch(() => {});
    } else {
      this.loading = true;
      if (this.projects && this.projects.length > 0) {
        this.loading = false;
      }
      this.getProjects();
    }

    window.addEventListener("scroll", this.updateScroll);
  },
  beforeDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  },

  updated: function () {
    window.scrollTo(0, parseInt(this.scrollPosition));
  },

  computed: {
    totalPages() {
      return Math.ceil(this.total_count / this.itemsPerPage);
    },
    projects: {
      get() {
        return this.$store.getters.getProjects;
      },
      set() {
        return this.$store.getters.getProjects;
      },
    },
    focus_mode() {
      return this.$store.state.focus_mode;
    },
    filtered() {
      let items = [];
      if (this.projects && this.projects.length > 0) {
        if (this.filters.by_ref) {
          items = this.projects.filter(
            (project) => project.referrer == this.user.username
          );
        } else if (this.filters.by_delegate) {
          items = this.projects.filter(
            (project) => project.delegate == this.user.username
          );
        } else if (this.focus_mode && this.user.role == "master") {
          let new_projects = this.projects
            .filter(
              (item) =>
                item.last_status == "in attesa" &&
                item.delegate == this.user.username
            )
            .concat(
              this.projects.filter((item) => item.last_status == "completato")
            );
          let prevs = new_projects.slice(1);
          prevs.forEach((project) => {
            project.isLocked = true;
          });
          this.$store.dispatch("setUnlockedProject", new_projects[0].id);
          items = [new_projects[0]].concat(prevs);
        } else {
          items = this.projects;
        }
      }
      return items;
    },
    focus_mode_icon() {
      let default_icon = "mdi-eye-circle-outline";
      if (this.focus_mode) {
        default_icon = "mdi-eye-circle";
      }
      return default_icon;
    },
    user() {
      return this.$store.getters.getCurrentUser;
    },
  },
  methods: {

    _mapQuotationStatus(item) {
      item.status = item.quotations[0].status
      return mapQuotationStatus(item);
    },
    handlePriorityStatus(priority) {
      return mapPriorityStatus(priority);
    },
    checkIfLocked(item) {
      if (item.isLocked) {
        return "item-locked";
      }
    },
    setFilters() {
      axios
        .post("/set-filters/", { filters: JSON.stringify(this.filters) })
        .then((response) => {
          this.filters = response.data.filters;
          this.$store.state.currentUser.filters = response.data.filters;
        });
    },
    toggleFocusMode() {
      let text = "Vuoi attivare la focus mode?";
      if (this.focus_mode) text = "Vuoi disattivare la focus mode?";
      this.$confirm(text).then((result) => {
        if (result) {
          axios
            .post("toggle-focus-mode/", {
              focus_mode: JSON.stringify({ focus_mode: this.focus_mode }),
            })
            .then((response) => {
              this.focus_mode = response.data.focus_mode;
              this.$store.state.focus_mode = response.data.focus_mode;
            });
        }
      });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    saveSearch() {
      localStorage.setItem("projects_search", this.search);
    },
    newProject() {
      axios.post("project").then((response) => {
        this.is_disabled = true;
        this.$router.push("/project/" + response.data.id).catch(() => {});
        setTimeout(function () {
          this.is_disabled = false;
        }, 3000);
      });
    },
    goTo(a) {
      if (event.target.classList.contains("btn__content")) return;
      this.$router.push("/project/" + a._id).catch(() => {});
    },
    getProjects() {
      axios
        .get(
          `project?page=${this.page}&search=${this.search}&customer=${
            this.selectedCustomer ? this.selectedCustomer._id : ""
          }`
        )
        .then((response) => {
          this.total_count = response.data.total;
          this.$store.dispatch("setProjects", response.data.projects);
          this.scrollPosition = response.data.scrolly;
          this.customers = response.data.customers;
          this.loading = false;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },

    getColor(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "in lavorazione") {
        return "red";
      } else if (status == "creato") {
        return "black";
      } else {
        return "green";
      }
    },
    getpStatus(status) {
      if (status == "in attesa") {
        return "#ffc412";
      } else if (status == "aperto") {
        return "blue";
      } else if (status == "da completare") {
        return "#ffc412";
      } else if (status == "in produzione") {
        return "red";
      } else if (status == "file da caricare") {
        return "#2f2f2f";
      } else if (status == "completato") {
        return "green";
      } else {
        return "transparent";
      }
    },
    getSeen(view) {
      if (view == "X") {
        return "";
      } else {
        return "#abebe0";
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    getQuotationStatus(elements) {
      var counter_0 = 0;
      var counter_1 = 0;

      for (var i = 0; i < elements.length; i++) {
        if (elements[i].status == 0) {
          counter_0 = counter_0 + 1;
        } else if (elements[i].status == 1) {
          counter_1 = counter_1 + 1;
        }
      }

      if (counter_0 > 0) {
        return { status: "In Attesa di Prev", color: "#474747" };
      } else if (counter_1 > 0) {
        return { status: "In Attesa", color: "#ffc412" };
      } else if (elements.length == 0) {
        return { status: "", color: "transparent" };
      } else if (elements.length > 0) {
        return { status: "Completato", color: "#4CAF50" };
      }
    },
  },
};
</script>

<style scoped>
.capitalizer {
  text-transform: capitalize;
}

.full-height {
  height: 100%;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.item {
  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}

.v-data-footer__select {
  display: none !important;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {
  z-index: 2000;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.text-center {
  text-align: center;
}

.force-square {
  border-radius: 0px !important;
}

.bigger-badge {
  font-size: 10px !important;
}

.dt {
  width: 100%;
}
</style>
