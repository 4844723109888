<template>
  <div>
    <primary-btn text="Nuovo Utente" v-on:click.native="dialog = true" dark />
    <v-dialog v-model="dialog" persistent max-width="600px">
      <form class="newuser-form">
        <v-card>
          <v-card-title>
            <span class="headline">Nuovo utente</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  required
                  label="Nome utente"
                  v-model="user.username"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password"
                  v-model="user.password"
                  required
                  type="password"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  required
                  label="Email"
                  v-model="user.email"
                ></v-text-field>
                <v-select
                  v-if="!selectedRole"
                  v-model="user.role"
                  :items="roles"
                  required
                  item-text="description"
                  item-value="name"
                  label="Ruolo"
                ></v-select>
                <div v-else>
                  <div class="tw-text-lg"><b>Ruolo:</b> {{ selectedRole.description }}</div>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="user.role == 'esterno'"
                  v-model="user.esterno"
                  required
                  label="Nome del collaboratore"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-if="user.role == 'esterno'"
                  v-model="user.agent"
                  :items="filteredAgents"
                  required
                  item-text="username"
                  item-value="username"
                  label="Associa un commerciale"
                ></v-select>
              </v-col>
              <v-col v-if="user.role == 'supplier'" cols="12">
                <v-select
                  item-text="username"
                  item-value="id"
                  v-model="user.machineSuppliers"
                  :items="machineSuppliersItems"
                  label="Seleziona i fornitori da associare"
                  :menu-props="{ maxHeight: '400' }"
                  multiple
                  required
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  item-text="label"
                  item-value="value"
                  v-if="user.role == 'supplier'"
                  v-model="user.quotationType"
                  :items="quotationTypes"
                  required
                  label="Tipo di fornitore"
                />
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="user.org_id"
                  :items="orgs"
                  item-text="name"
                  item-value="id"
                  label="Organizzazione "
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">Annulla</v-btn>
            <v-btn color="blue darken-1" text @click="newUser"> Salva </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <response-notification></response-notification>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import { checkForm } from "../plugins/utils";

export default {
  name: "UserDialog",
  props: ["roles", "agents", "selectedRole", "machineSuppliersItems", "orgs"],
  data() {
    return {
      dialog: false,
      quotationTypes: [
        {
          value: "preventivi_stampa",
          label: "Preventivi stampa",
        },
        {
          value: "preventivi_fustella",
          label: "Preventivi fustella",
        },
      ],
      user: {
        username: "",
        password: "",
        email: "",
        role: "",
        esterno: "",
        agent: "",
        quotationType: "",
        machineSuppliers: [],
        org_id: null,
      },
    };
  },
  computed: {
    filteredAgents() {
      return this.agents.filter((item) => item.role == "commerciale");
    },
  },
  methods: {
    newUser() {
      if (checkForm("newuser-form")) {
        this.selectedRole ? (this.user.role = this.selectedRole.name) : null;
        axios
          .post("user", this.user)
          .then((response) => {
            this.$emit("update-success");
            this.$store.dispatch("notificate", {
              text: response.data.msg.text,
              type: response.data.msg.type,
            });
            if (
              response.data.msg.type != "error" &&
              response.data.msg.type != "warning"
            ) {
              this.dialog = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style scoped>
.newuser-form {
  width: 100%;
}
</style>

