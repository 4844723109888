<template>


  <v-container fluid>
    <v-row>
      <v-col cols="12" class="!tw-p-0 tw-items-center tw-mb-4">
        <div class="tw-flex md:tw-items-center md:tw-flex-row tw-flex-col">
          <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">Plotter</h1>
          <input-field class="md:tw-ml-8 md:!tw-w-96 !tw-w-full" type="text" icon="mdi-magnify" v-model="search"
            placeholder="Cerca tra le produzioni" @input="saveSearch"></input-field>
        </div>
      </v-col>
      <v-skeleton-loader :loading="loading" transition="fade-transition" class="dt" height="500" type="table">
        <v-data-table hide-default-footer :headers="headers" :items="projects" loading="true"
          loading-text="Sto caricando..." :items-per-page="itemsPerPage"
          class="elevation-1 item dt font2 !tw-rounded-lg projects" height="100%" :search="search"
          @page-count="pageCount = $event" :page.sync="page">
          <!-- Aggiungiamo uno slot per personalizzare ogni riga -->
          <template v-slot:item="{ item, index }">
            <tr
              @click="goTo(item)"
              :class="{ 'focused-row': isFocusModeActive && index === 0, 'unfocused-row': isFocusModeActive && index !== 0 }">
              <td>{{ item.id_project }}</td>
              <td>{{ item.customer }}</td>
              <td>{{ item.ref }}</td>
              <td>
                <div v-if="item.delivery_date && tomorrow(item.delivery_date).isExpiring" style="position: relative">
                  {{ tomorrow(item.delivery_date).date }}
                  <span v-if="item.status != 'completato'" class="expiring">{{ tomorrow(item.delivery_date).text
                    }}</span>
                </div>
                <div v-else-if="item.delivery_date && !tomorrow(item.delivery_date).isExpiring">
                  {{ formatDate(item.delivery_date) }}
                </div>
              </td>
              <td>
                <v-chip color="transparent">
                  {{ JSON.parse(item.material).onda }}
                </v-chip>
              </td>
              <td>
                <v-chip :color="getColor(item.status)" dark class="capitalizer">
                  {{ item.status }}
                </v-chip>
              </td>
              <td>{{ item.last_edit }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <div class="text-center pt-2">
        <v-pagination color="#11797b" class="pag" v-model="page" :length="pageCount"></v-pagination>
      </div>
    </v-row>
  </v-container>

</template>

<script>
import axios from 'axios'


export default {
  name: 'Plotter',

  data() {
    return {
      search: '',
      loading: true,
      timeout: 7000,
      page: 1,
      itemsPerPage: 100,
      pageCount: 0,
      headers: [
        { text: "ID", value: "id_project", sortable: false, filterable: false },
        { text: "Nome Cliente", value: "customer", sortable: false, filterable: true },
        { text: "Referenza", value: "ref", sortable: false, filterable: true },
        { text: "Consegna richiesta", value: "delivery_date", align: "start", sortable: false },
        { text: "Materiale", value: "material", sortable: false, filterable: false },
        { text: "Stato", value: "status", sortable: false, },
        { text: "Ultima Richiesta", value: "last_edit", align: "start", sortable: false },
        //{ text: "Quantità prodotte", value: "produced_qty", align: "start", sortable: false },

      ],
      projects: [],
    }
  },
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    isFocusModeActive() {
      console.log(this.user)
      return (this.user?.focus_mode_plotter && this.user.role == 'master'); // Controlla se la focus mode è attiva
    },
  },
  beforeCreate: function () {
    axios.get('production')
      .then((response) => {
        if (response.data.status != "logged_in") {
          this.$router.push('/login').catch(() => { });
        }

      })

  },

  mounted: function () {
    console.log(this.user)
    this.tomorrow()
    this.getProjects()

  },
  watch: {
    projects() {
      setTimeout(() => this.getProjects(), this.timeout);
    }

  },
  methods: {
    saveSearch() {
      localStorage.setItem("plotter_search", this.search);
    },
    formatDate: function (date) {
      var month = date.split("-")[1];
      var day = date.split("-")[2];
      var year = date.split("-")[0];

      return [day, month, year].join('-');

    },
    getProjects() {
      axios.get('production')
        .then((response) => {
          this.projects = response.data.production;
          this.search = localStorage.getItem("plotter_search");
          this.loading = false

        })
    },
    goTo(a) {
      if (event.target.classList.contains('btn__content')) return;
      this.$router.push('/to-production/' + a._id).catch(() => { });

    },
    getColor(status) {
      if (status == "in attesa") {
        return "#ffc412"
      }

      else if (status == "aperto") {
        return "blue"
      }

      else if (status == "in produzione") {
        return "red"
      }
      else if (status == "completato") {
        return "green"
      }

      else if (status == "file da caricare") {
        return
      }
      else {
        return "#c0c0c0"
      }
    },
    tomorrow(delivery) {
      var today = new Date()
      var delivery_date = new Date(delivery)
      var delay = this.findDayDifference(today, delivery_date)

      if (delivery) {
        var formatted_date = delivery_date.toLocaleString().split(",")[0].replaceAll('/', "-")
        if (delay == -1) {
          return { date: formatted_date, isExpiring: 1, text: "In scadenza oggi" }
        }

        else if (delay == 1) {
          return { date: formatted_date, isExpiring: 1, text: "In scadenza domani" }
        }

        else if (delay < -1) {
          return { date: formatted_date, isExpiring: 1, text: "Scaduto" }
        }

        else {
          return { date: formatted_date, isExpiring: 0 }
        }
      }

    },
    findDayDifference(date1, date2) {
      return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24))
    }
  }



}


</script>

<style scoped>
.expiring {
  background-color: tomato;
  color: white;
  position: absolute;
  left: 80px;
  width: max-content;
  padding: 1px 3px;
  border-radius: 2px;
  font-size: 11px;

}

.capitalizer {

  text-transform: capitalize;
}

.full-height {

  height: 100%;

}

.elevation-1 {

  width: 100%;
  box-sizing: border-box;

}

.item {

  cursor: pointer;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 48.5%;
}

.v-data-footer__select {

  display: none !important;
}

.action-tooltip {

  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action {

  z-index: 2000;
}

.block-action:hover .action-tooltip {

  visibility: visible;

}

.text-center {

  text-align: center;
}

.force-square {

  border-radius: 0px !important;
}

.bigger-badge {

  font-size: 10px !important;
}

.dt {
  width: 100%;
}

.focused-row {
  opacity: 1 !important;
}

.unfocused-row {
  opacity: 0.5;
}
</style>
