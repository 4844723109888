<template>
  <v-container fluid>
    <!-- Organizzazioni Section -->
    <v-row class="tw-items-center">
      <v-col cols="8">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start">
          ICA
        </h1>
      </v-col>
      <v-col cols="4" class="no-pd d-flex justify-end tw-items-center">
        <v-btn class="!tw-rounded-xl !tw-capitalize !tw-bg-azure !tw-text-dark" dark
          @click="openEditOrgDialog(icaOrg)">
          Modifica ICA
        </v-btn>
        <user-dialog @update-success="getData()" class="!tw-mx-4" :agents="agents" :roles="roles"
          :orgs="rawOrgs.filter(org => org.name !== 'ICA')" :machineSuppliersItems="machine_suppliers" />
        <v-btn class="!tw-text-white !tw-rounded-xl !tw-capitalize !tw-bg-custom-blue" dark
          @click="newOrgDialog = true">
          Nuova Organizzazione
        </v-btn>
      </v-col>
      <v-data-table v-if="icaOrg" hide-default-footer :headers="headers" :items="icaOrg.users" loading="true"
        loading-text="Sto caricando..." :items-per-page="9999999999999" class="elevation-1 item tw-w-full" height="100%"
        :search="search">
        <template v-slot:item.username="{ item }">
          <td>
            {{ item.username }}
          </td>
        </template>
        <template v-slot:item.email="{ item }">
          <td>
            {{ item.email }}
          </td>
        </template>
        <template v-slot:item.role="{ item }">
          <td>
            {{ mapRole(item.role) }}
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon @click="openEditUserDialog(item)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
      <v-col cols="8">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start tw-mt-12">
          Impianto Stampa
        </h1>
      </v-col>

      <v-expansion-panels>
        <v-expansion-panel v-for="(org, i) in stampaOrgs" :key="i">
          <v-expansion-panel-header>
            <span style="width: 90%">{{ org.name }}</span>
            <v-btn icon @click.stop="openEditOrgDialog(org)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table hide-default-footer :headers="headers" :items="org.users" loading="true"
              loading-text="Sto caricando..." :items-per-page="9999999999999" class="elevation-1 item" height="100%"
              :search="search" style="width: 100%; margin-top: ">
              <template v-slot:item.username="{ item }">
                <td v-if="!item.is_editing">
                  {{ item.username }}
                </td>
                <v-text-field style="font-size: 14px" v-else v-model="item.username"></v-text-field>
              </template>
              <template v-slot:item.email="{ item }">
                <td v-if="!item.is_editing">
                  {{ item.email }}
                </td>
                <v-text-field style="font-size: 14px" v-else v-model="item.email"></v-text-field>
              </template>
              <template v-slot:item.role="{ item }">
                <td v-if="!item.is_editing">
                  {{ mapRole(item.role) }}
                  <span v-if="!item.role && item.org_id"> (Ereditato dalla organizzazione)</span>
                </td>
                <v-select item-text="description" item-value="name" style="font-size: 14px" :items="roles" v-else
                  v-model="item.role"></v-select>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="!item.is_editing" @click="openEditUserDialog(item)">
                  mdi-pencil
                </v-icon>
                <td class="d-flex" v-else>
                  <v-icon @click="updateUser(item)" color="green">
                    mdi-check-circle
                  </v-icon>
                  <v-icon @click="deleteUser(item)" color="red">
                    mdi-delete
                  </v-icon>
                  <v-icon @click="cancel(item)" color="orange">
                    mdi-close-circle
                  </v-icon>
                </td>
                <v-icon class="tw-ml-2" @click="resetPriorityLimits(item.username)">
                  mdi-restore
                </v-icon>
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-col cols="8">
        <h1 class="tw-text-3xl tw-font-bold tw-justify-self-start tw-mt-12">
          Fustella
        </h1>
      </v-col>
      <v-expansion-panels>
        <v-expansion-panel v-for="(org, i) in fustellaOrgs" :key="i">
          <v-expansion-panel-header>
            <span style="width: 90%">{{ org.name }}</span>
            <v-btn icon @click.stop="openEditOrgDialog(org)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table hide-default-footer :headers="headers" :items="org.users" loading="true"
              loading-text="Sto caricando..." :items-per-page="9999999999999" class="elevation-1 item" height="100%"
              :search="search" style="width: 100%; margin-top: ">
              <template v-slot:item.username="{ item }">
                <td v-if="!item.is_editing">
                  {{ item.username }}
                </td>
                <v-text-field style="font-size: 14px" v-else v-model="item.username"></v-text-field>
              </template>
              <template v-slot:item.email="{ item }">
                <td v-if="!item.is_editing">
                  {{ item.email }}
                </td>
                <v-text-field style="font-size: 14px" v-else v-model="item.email"></v-text-field>
              </template>
              <template v-slot:item.role="{ item }">
                <td v-if="!item.is_editing">
                  {{ mapRole(item.role) }}
                  <span v-if="!item.role && item.org_id"> (Ereditato dalla organizzazione)</span>
                </td>
                <v-select item-text="description" item-value="name" style="font-size: 14px" :items="roles" v-else
                  v-model="item.role"></v-select>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="!item.is_editing" @click="openEditUserDialog(item)">
                  mdi-pencil
                </v-icon>
                <td class="d-flex" v-else>
                  <v-icon @click="updateUser(item)" color="green">
                    mdi-check-circle
                  </v-icon>
                  <v-icon @click="deleteUser(item)" color="red">
                    mdi-delete
                  </v-icon>
                  <v-icon @click="cancel(item)" color="orange">
                    mdi-close-circle
                  </v-icon>
                </td>
                <v-icon class="tw-ml-2" @click="resetPriorityLimits(item.username)">
                  mdi-restore
                </v-icon>
              </template>

            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <!-- Fornitori Section -->
    <v-row class="!tw-mt-12">
      <v-col cols="8" class="tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold">Stampatore</h1>
      </v-col>
      <v-col cols="4" class="no-pd d-flex justify-end tw-items-center">
        <user-dialog :agents="agents" :selectedRole="{ name: 'machine_supplier', description: 'Stampatore' }" />
      </v-col>
      <v-expansion-panels>
        <v-expansion-panel v-for="(supplier, i) in machine_suppliers" :key="i">
          <v-expansion-panel-header>
            <span style="width: 90%">{{ supplier.username }}</span>
            <span>
              <EditSupplierForm :availableSuppliers="suppliers" :supplierData="supplier"
                @update-success="handleUpdateSuccess" :selectedSuppliers="supplier.suppliers" />
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="d-flex">
              <v-col cols="6" class="d-flex flex-column">
                <h3>Fornitori fustella</h3>
                <div v-for="(machine_supplier, x) in fust_suppliers(supplier)" :key="x">
                  - {{ machine_supplier.username }}
                </div>
              </v-col>
              <v-col cols="6" class="d-flex flex-column">
                <h3>Fornitori impianto stampa</h3>
                <div v-for="(machine_supplier, x) in stampa_suppliers(supplier)" :key="x">
                  - {{ machine_supplier.username }}
                </div>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>

    <Operators />

    <!-- App Settings Section -->
    <v-row class="!tw-mt-12">
      <v-col cols="12" class="tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold">Impostazioni app</h1>
      </v-col>
      <v-col cols="12" class="tw-items-center tw-bg-white tw-rounded-md">
        <h1 class="tw-text-xl tw-font-semibold">Sicurezza</h1>
        <div class="tw-flex tw-items-center">
          <div class="tw-flex tw-items-center">
            <div class="tw-text-lg tw-mr-4">Protezione Api</div>
            <v-switch :true-value="1" :false-value="0" v-model="appSettings.apiProtection" @change="updateAppSettings">
            </v-switch>
          </div>
        </div>
        <div>
          Permette di effettuare richieste solamente al gestionale e blocca
          richieste esterne
        </div>
      </v-col>
    </v-row>

    <!-- Schedules Section -->
    <v-row class="!tw-mt-12">
      <v-col cols="12" class="tw-items-center">
        <h1 class="tw-text-3xl tw-font-bold">Schedules</h1>
      </v-col>
    </v-row>

    <!-- New Supplier Dialog -->
    <v-dialog v-model="new_supplier_dialogue" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"><b>Nuovo fornitore</b></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field outlined v-model="new_supplier_name"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="new_supplier_dialogue = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="newSupplier"> Salva </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- New Organization Dialog -->
    <v-dialog v-model="newOrgDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline"><b>Nuova Organizzazione</b></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field outlined v-model="newOrg.name" label="Nome Organizzazione"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined v-model="newOrg.description" label="Descrizione"></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="tw-mb-4 tw-font-bold">Selezionando il ruolo tutti i membri all'interno lo erediteranno, lascia
                vuoto se non vuoi che accada</div>
              <v-select label="Ruolo" placeholder="Seleziona ruolo" clearable outlined item-text="description"
                item-value="name" style="font-size: 14px" :items="roles" v-model="newOrg.role"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newOrgDialog = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="createOrganization">
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit Organization Dialog -->
    <v-dialog v-model="editOrgDialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"><b>Modifica Organizzazione</b></span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field outlined v-model="editOrg.name" label="Nome Organizzazione"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field outlined v-model="editOrg.description" label="Descrizione"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select outlined v-model="editOrg.role" :items="roles" label="Ruolo" item-text="description"
                item-value="name"></v-select>
            </v-col>
            <v-col cols="12">
              <v-select outlined v-model="editOrg.users" :items="users" label="Aggiungi/Disassocia Utenti"
                item-text="username" item-value="id" multiple></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editOrgDialog = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="updateOrganization">
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Edit User Dialog -->
    <edit-user-dialog :userData="selectedUser" :roles="roles" :agents="agents" :machineSuppliersItems="suppliers"
      :orgs="orgs" @update-success="handleUpdateSuccess" />
    <confirm-dialog></confirm-dialog>
    <response-notification></response-notification>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import EditSupplierForm from "../components/EditSupplierForm.vue";
import UserDialog from "../components/UserDialog.vue";
import EditUserDialog from "../components/EditUserDialog.vue";
import Operators from "../components/Admin/ProductionOperators/Operators.vue";

export default {
  name: "Admin",
  components: {
    EditSupplierForm,
    UserDialog,
    EditUserDialog,
    Operators
  },
  data() {
    return {
      rawOrgs: [],
      agents: [],
      appSettings: {},
      new_supplier_name: "",
      search: "",
      timeout: 7000,
      headers: [
        { text: "ID", value: "id", sortable: true, filterable: false },
        { text: "Username", value: "username", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Ruolo", value: "role", sortable: false },
        { text: "Stato", value: "status", sortable: false },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      headers_sup: [
        { text: "ID", value: "id", sortable: true, filterable: false },
        { text: "Nome", value: "name", sortable: false },
        {
          text: "Fornitori fustella",
          value: "fustella",
          sortable: false,
          filterable: false,
        },
        {
          text: "Fornitori impianto stampa",
          value: "impianto_stampa",
          sortable: false,
          filterable: false,
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ],
      pagination: {
        rowsPerPage: 100,
        descending: false,
        sortBy: "name",
        page: 1,
      },
      users: [],
      roles: [],
      new_supplier_dialogue: false,
      newOrgDialog: false,
      editOrgDialog: false,
      newOrg: {
        name: "",
        description: "",
        role: "",
      },
      editOrg: {
        id: "",
        name: "",
        description: "",
        role: "",
        users: [],
      },
      selectedUser: null,
    };
  },

  created() {
    this.getData();
  },
  computed: {
    icaOrg() {
      return this.orgs.find(org => org.name === 'ICA');
    },
    suppliers() {
      return this.users.filter(user => user.role === 'supplier');
    },
    machine_suppliers() {
      return this.users.filter(user => user.role === 'machine_supplier');
    },
    stampaOrgs() {
      return this.orgs.filter(org =>
        org.users.some(user => user.supplier_type === "preventivi_stampa")
      );
    },
    fustellaOrgs() {
      return this.orgs.filter(org =>
        org.users.some(user => user.supplier_type === "preventivi_fustella")
      );
    },
    orgs() {
      const orgsMap = {};

      this.users.forEach(user => {
        if (user.organization) {
          if (!orgsMap[user.organization.id]) {
            orgsMap[user.organization.id] = {
              ...user.organization,
              users: []
            };
          }
          orgsMap[user.organization.id].users.push(user);
        }
      });

      return Object.values(orgsMap);
    }
  },
  methods: {
    fust_suppliers(machine_supplier) {
      return machine_supplier.suppliers.filter(
        (item) => item.supplier_type == "preventivi_fustella"
      );
    },
    stampa_suppliers(machine_supplier) {
      return machine_supplier.suppliers.filter(
        (item) => item.supplier_type == "preventivi_stampa"
      );
    },
    getData() {
      axios
        .get("admin")
        .then((response) => {
          this.appSettings = response.data.appSettings;
          this.users = response.data.users;
          this.roles = response.data.roles;
          this.users.forEach(user => {
            user.is_editing = false;
          });
          this.rawOrgs = response.data.organizations;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    updateAppSettings() {
      axios
        .patch("app-settings", this.appSettings)
        .then((res) => {
          this.$store.dispatch("notificate", {
            text: "Impostazioni aggiornate con successo",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    mapRole(current) {
      let currentRole = this.roles.find(item => item.name == current);
      return currentRole ? currentRole.description : current;
    },
    newSupplier() {
      axios
        .post("supplier", { name: this.new_supplier_name })
        .then((response) => {
          this.new_supplier_dialogue = false;
          this.$store.dispatch("notificate", {
            text: response.data.msg.text,
            type: response.data.msg.type,
          });
          this.suppliers.push({
            name: this.new_supplier_name,
            fustella: [],
            impianto_stampa: [],
            email: "",
          });
          this.new_supplier_name = "";
        });
    },
    createOrganization() {
      axios.post("organization", this.newOrg).then((response) => {
        this.$store
          .dispatch("notificate", {
            text: "Organizzazione creata con successo",
            type: "success",
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.newOrgDialog = false;
            this.getData();
          });
      });
    },
    handleUpdateSuccess() {
      this.getData();
    },
    openEditOrgDialog(org) {
      this.editOrg = {
        ...org,
        users: org.users.map(user => user.id),
      };
      this.editOrgDialog = true;
    },
    updateOrganization() {
      axios
        .patch(`organization/${this.editOrg.id}`, this.editOrg)
        .then((response) => {
          this.$store.dispatch("notificate", {
            text: "Organizzazione aggiornata con successo",
            type: "success",
          });
          this.editOrgDialog = false;
          this.getData();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    openEditUserDialog(user) {
      this.selectedUser = user;
    },
  },
};
</script>
<style scoped>
.cont {
  width: 100%;
}

#login-cont {
  width: 360px;
  height: auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.157);
  padding: 40px 10px 40px 10px;
}

.login-field,
.login-field:focus {
  margin-top: 5px;
  border-bottom: solid 1px black !important;
  border-radius: 0px !important;
  border: 0;
}

.btn-full {
  margin: 0 !important;
  border-radius: 2px !important;
}

#login_error {
  color: red;
}
</style>