<template>
  <v-container class="container-v md:tw-p-32 !tw-p-6 tw-pb-12" fluid>
    <v-row class="!tw-flex md:tw-flex-wrap tw-w-full">
      <v-col cols="12" class="!tw-p-0 md:tw-items-center tw-mb-8 tw-flex tw-flex-col md:tw-flex-row">
        <div class="tw-flex tw-items-center">
          <v-icon @click="$router.push('/production').catch(() => { })"
            class="!tw-text-dark tw-mr-4 hover:tw-opacity-80 tw-cursor-pointer">mdi-arrow-expand-left</v-icon>
          <h2 class="tw-text-3xl tw-font-bold">
            Produzione N° {{ prod_data.id_project }}
          </h2>
        </div>
        <div>
          <v-chip v-if="prod_data.status == 'file da caricare'" dark color="lightgray" class="tw-ml-4">
            In attesa di file
          </v-chip>
          <v-chip v-else-if="prod_data.status == 'in attesa'" dark color="orange" class="tw-ml-4">
            In attesa
          </v-chip>
          <v-chip v-else-if="prod_data.status == 'da completare'" dark color="lightgreen" class="tw-ml-4">
            Da completare
          </v-chip>
          <v-chip v-else-if="prod_data.status == 'completato'" dark color="green" class="tw-ml-4">
            Completata
          </v-chip>
        </div>
        <div class="tw-flex tw-justify-end tw-items-center tw-flex-1">
          <v-menu v-model="actions_dialog" offset-y content-class="!tw-shadow-xl !tw-rounded-lg !tw-w-auto !tw-min-w-0">
            <template v-slot:activator="{ on, attrs }">
              <secondary-btn v-bind="attrs" v-on="on" @click.native="actions_dialog = true" id="action-btn"
                class="tw-self-baseline" text="Altre Azioni" icon="mdi-menu-down"></secondary-btn>
            </template>
            <v-list class="font2">
              <action-element @click.native="goBack" text="Chiudi" icon="mdi-close-thick"></action-element>
              <action-element v-if="role != 'worker' && role != 'esterno'" color="blue" @click.native="
                $router
                  .push('/project/' + prod_data.id_project)
                  .catch(() => { })
                " text="Vai al progetto" icon="mdi-share"></action-element>
              <action-element v-if="role != 'worker'" color="red" @click.native="deleteProject"
                text="Elimina Produzione" icon="mdi-delete"></action-element>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="12" class="input-data font2">
        <div class="generic-info">
          <label><b>Cliente: </b></label>
          <div>{{ prod_data.customer }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="input-data font2">
        <div class="generic-info">
          <label><b>Riferimento: </b></label>
          <div>{{ prod_data.ref }}</div>
        </div>
      </v-col>
      <v-col cols="12" class="input-data font2">
        <div class="generic-info">
          <label><b>Ultima richiesta di produzione: </b></label>
          <div v-if="prod_data.last_edit">{{ prod_data.last_edit }}</div>
          <div v-else>{{ prod_data.creation }}</div>
        </div>
      </v-col>
      <v-col class="input-data font2">
        <div class="generic-info">
          <label><b>Ultima richiesta di produzione: </b></label>
          <div v-if="prod_data.delivery_date">
            {{ formatDate(prod_data.delivery_date) }}
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row class="tw-flex-1 tw-flex md:tw-flex-row tw-flex-col tw-rounded-xl  tw-bg-white">
      <v-col class="md:tw-w-1/2 tw-w-full">
        <v-card flat height="100%" class="card-cont">
          <v-row class="time-content">
            <v-row class="content-row font2">
              <v-col sm="12">
                <div class="generic-info">
                  <label><b>Quantità da produrre: </b></label>
                  <div>{{ prod_data.qty }}</div>
                </div>
              </v-col>
              <v-col sm="12">
                <div class="generic-info">
                  <label><b>Onda: </b></label>
                  <br />
                  <div>{{ material.onda }}</div>
                </div>
              </v-col>
              <v-col sm="12">
                <div class="generic-info">
                  <label><b>Copertina Esterna: </b></label>
                  <br />
                  <div>{{ material.copertina_est }}</div>
                </div>
              </v-col>
              <v-col sm="12">
                <div class="generic-info">
                  <label><b>Copertina Interna: </b></label>
                  <br />
                  <div>{{ material.copertina_int }}</div>
                </div>
              </v-col>
              <v-col v-if="prod_data.notes" sm="12">
                <div class="generic-info">
                  <label><b>Note: </b></label>
                  <div>{{ prod_data.notes }}</div>
                </div>
              </v-col>
              <v-col v-if="prod_data.pallet" sm="12">
                <div class="generic-info">
                  <label><b>Palletizzazione: </b></label>
                  <div>
                    {{ prod_data.pallet }}, {{ prod_data.bancale }},
                    {{ prod_data.legato }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-row>
          <v-row class="files nopd nomg font2">
            <v-col sm="12" class="form-allegato">
              <v-col sm="12">
                <div class="generic-info">
                  <label><b>File per la produzione: </b></label>
                </div>
              </v-col>

              <form enctype="multipart/form-data" id="allegato-prod" class="form-ica">
                <label class="uploader">
                  <div v-if="prod_files.length == 0">
                    Non sono presenti file...
                  </div>

                  <v-row class="allegato-row" v-for="(fname, index) in prod_files" :key="index">
                    <v-col class="nopd center_y" sm="10">{{
                      fname.replace(/_/g, " ")
                    }}</v-col>
                    <v-col class="nopd" sm="2">
                      <v-col cols="12" class="nopd right">
                        <v-icon class="action-file" @click="downloadFile(fname, 'production', index)">
                          mdi-download
                        </v-icon>
                        <v-icon v-if="role != 'worker'" class="action-file" @click="deleteprodfiles(index)">
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-col>
                  </v-row>
                </label>
              </form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="md:tw-w-1/2 tw-w-full">
        <v-row v-if="role == 'worker'">
          <div class="tw-w-full tw-flex tw-flex-col tw-my-8">
            <div class="tw-font-semibold tw-text-xl">Azioni</div>
            <div class="tw-flex tw-mt-4" v-if="prod_data.status == 'file da caricare'">
              <div class="font2">In attesa di caricamento file...</div>
            </div>
            <div class="tw-flex tw-mt-4" v-if="prod_data.status == 'in attesa'">
              <primary-btn text="Inizia" @click.native="Task('start')"></primary-btn>
              <primary-btn class="tw-ml-2" text="Ferma" :disabled="true"></primary-btn>
            </div>
            <div class="tw-flex tw-mt-4" v-if="prod_data.status == 'in produzione'">
              <primary-btn text="Inizia" :disabled="true"></primary-btn>
              <primary-btn class="tw-ml-2" text="Ferma" @click.native="Task('end')"></primary-btn>
            </div>
            <div class="tw-flex tw-mt-4" v-if="prod_data.status == 'da completare' || prod_data.status == 'in attesa'">
              <primary-btn text="Riprendi" @click.native="Task('start')"></primary-btn>
              <primary-btn class="tw-ml-2" text="Ferma" :disabled="true"></primary-btn>
              <confirm-btn class="tw-ml-2" text="Completa" @click.native="Completed"></confirm-btn>
            </div>
            <div class="tw-flex tw-mt-4" v-if="prod_data.status == 'completato'">
              <div class="font2">Produzione completata</div>
            </div>
          </div>
        </v-row>
        <!-- Aggiungiamo una nuova riga per master e commerciale -->
        <v-row v-else-if="(role == 'master' || role == 'commerciale') && prod_data.status == 'in attesa'">
          <div class="tw-w-full tw-flex tw-flex-col tw-my-8">
            <div class="tw-font-semibold tw-text-xl">Azioni</div>
            <div class="tw-flex tw-mt-4">
              <confirm-btn text="Completa" @click.native="Completed"></confirm-btn>
            </div>
          </div>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-card-title class="time-title">Produzione corrente</v-card-title>
          </v-col>
          <v-col cols="12">
            <v-simple-table class="tw-overflow-x-auto">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Inizio</th>
                    <th class="text-left">Fine</th>
                    <th class="text-left">Totale</th>
                    <th class="text-left">Quantità prodotte</th>
                    <th class="text-left">Quantità rimanenti</th>
                    <th class="text-left">Operatore</th>
                  </tr>
                </thead>
                <tbody v-if="
                  production_time[production_time.length - 1] &&
                  production_time[production_time.length - 1].length < 1
                ">
                  <tr v-for="(item, index) in production_time[
                    production_time.length - 2
                  ]" :key="index">
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.end_time }}</td>
                    <td>{{ item.final_time }}</td>
                    <td>{{ item.produced }}</td>
                    <td>{{ item.remaining }}</td>
                    <td>{{ item.operator }}</td>
                  </tr>
                </tbody>
                <tbody v-else-if="production_time[production_time.length - 1]">
                  <tr v-for="(item, index) in production_time[
                    production_time.length - 1
                  ]" :key="index">
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.end_time }}</td>
                    <td>{{ item.final_time }}</td>
                    <td>{{ item.produced }}</td>
                    <td>{{ item.remaining }}</td>
                    <td>{{ item.operator }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div style="
                width: 100%;
                text-align: center;
                background-color: lightgrey;
                padding-top: 2px;
                padding-bottom: 5px;
              " v-if="prod_data.status == 'completato'">
              <p>Produzione terminata</p>
            </div>
          </v-col>

          <v-divider></v-divider>

          <v-col cols="12" class="tw-flex">
            <v-card-title class="time-title">Cronologia</v-card-title>
            <primary-btn class="tw-ml-2" text="Visualizza" @click.native="time_data_dialog = true"></primary-btn>

            <v-dialog v-model="time_data_dialog" content-class="dl-time" scrollable max-height="600px">
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  Cronologia
                </v-card-title>

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Inizio</th>
                        <th class="text-left">Fine</th>
                        <th class="text-left">Totale</th>
                        <th class="text-left">Quantità prodotte</th>
                        <th class="text-left">Quantità rimanenti</th>
                        <th class="text-left">Operatore</th>
                      </tr>
                    </thead>
                    <tbody v-for="(block, index) in production_time" :key="index">
                      <tr v-for="(item, index2) in block" :key="index2">
                        <td>{{ item.start_time }}</td>
                        <td>{{ item.end_time }}</td>
                        <td>{{ item.final_time }}</td>
                        <td>{{ item.produced }}</td>
                        <td>{{ item.remaining }}</td>
                        <td>{{ item.operator }}</td>
                      </tr>
                      <div style="
                          width: 100%;
                          text-align: center;
                          background-color: lightgrey;
                          padding-top: 2px;
                          padding-bottom: 5px;
                          position: absolute;
                        "></div>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="time_data_dialog = false">
                    Chiudi
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="md:tw-h-0 tw-h-40"></div>
    <v-dialog v-model="dialog_production" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Inserisci il tuo nome e cognome</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Nome operatore*" required v-model="prod_data.operator"></v-text-field>
            </v-col>

            <!--
            </v-row>
            <v-row>
              <v-col
                cols="6"
              >
                <v-text-field
                  label="Resa*"
                  required
                  type="number"
                  v-model="prod_data.resa"
                  @input="calcCopies(prod_data.resa)"
                  

                ></v-text-field>    



              </v-col>

            </v-row>                 
            <v-row>
              <v-col
                cols="12"
              >
                <v-alert 
                v-if="copies.qty > 0 && prod_data.resa > 0"
                outlined
                color="#1f7a8c"
                type="info"

                >

                <p v-if="copies.qty > 0 && copies.scarto == 0">Verranno prodotti {{copies.qty}} fogli</p>
                <p v-else-if="copies.qty > 0 && copies.scarto > 0">Verranno prodotti {{copies.qty}} fogli con {{prod_data.resa}} pezzi aggiuntivi</p>
                <p v-else-if="copies.qty == 1">Verrà prodotto un foglio</p>
              
                </v-alert>



              </v-col>
-->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_production = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Inizia
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_qty_produced" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Dati Produzione</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field label="Quantità Prodotte*" required v-model="produced_qty"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_qty_produced = false">
            Annulla
          </v-btn>
          <v-btn color="blue darken-1" text @click="confirmProduction">
            Conferma Fine Produzione
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="loading">
      <div class="shape">
        <v-progress-circular :rotate="360" :size="200" :width="25" :value="uploading" color="#1f7a8c" class="loading">
          {{ uploading }}
        </v-progress-circular>
      </div>
    </div>

    <v-alert elevation="7" :type="response_type" :value="success" class="onsuccess">
      {{ success_text }}
    </v-alert>

    <v-alert v-if="isEditing && isEditing != current_user" elevation="7" type="warning" :value="true" class="isediting">
      {{ isEditing }} sta lavorando al progetto.
    </v-alert>
    <response-notification></response-notification>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
const fs = require("fs");

export default {
  name: "Project",
  components: {},

  data() {
    return {
      actions_dialog: false,
      fab: false,
      copies: {},
      delegate: "",
      response_type: "success",
      tabs: "mobile-tabs-5-1",
      project: {},
      prod_data: {},
      current_msg: "",
      customer_name: "",
      project_name: "",
      project_referrer: "",
      project_status: "",
      project_time: "",
      current_user: [],
      colori: "",
      ritiro: "",
      role: "",
      start_time_produzione: "",
      end_time_produzione: "",
      produced_qty: 0,

      canEdit: undefined,
      isDisabled: false,
      isEditing: "",
      overlay: "",

      customers: [],

      dialog: false,
      email_dialog: false,
      time_data_dialog: false,
      dialog_production: false,
      dialog_qty_produced: false,
      current_prod_operation: "",

      time: "",
      date: "",
      timestamp: "",
      start_time: {},
      end_time: {},
      requests: [],

      production_time: "",

      uploadPercentage: 0,
      success: false,
      success_text: "",
      loading: false,
      file_uploader: "",

      ica_files: [],
      customer_files: [],
      prod_files: [],
      files_prod_list: new FormData(),

      input_isDisabled: true,

      result_time: "",
      edited: false,
      uploading: 0,

      pieces_prod: 0,
      notes_prod: "",
      material: {},
    };
  },

  beforeCreate: function () {
    axios.get("to-prod/" + this.$route.params.id).then((response) => {
      if (response.data.status == "not_logged") {
        this.$router.push("/login").catch(() => { });
      }

      this.role = response.data.role;
      this.prod_files = response.data.files;
      this.prod_data = response.data.production;
      this.material = response.data.material;
      try {
        this.production_time = response.data.produzione;
      } catch { }
      try {
        this.start_time_produzione =
          response.data.produzione[response.data.produzione.length - 1].slice(
            -1
          )[0].start_time;
        this.end_time_produzione =
          response.data.produzione[response.data.produzione.length - 1].slice(
            -1
          )[0].end_time;
      } catch { }
    });
  },

  beforeRouteLeave(to, from, next) {
    if (this.edited == true) {
      this.$confirm("Hai dati non salvati, sei sicuro di voler uscire?").then(
        (res) => {
          if (res) {
            next();
          } else {
            next(false);
          }
        }
      );
    } else {
      next();
    }
  },

  methods: {
    createXml() {
      var xml_string =
        '<?xml version="1.0" encoding="utf-8"?><Task xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema"><Name>' +
        this.prod_data.ref +
        "__" +
        this.prod_data.id_project +
        "__" +
        "</Name><JobID>" +
        this.prod_data.id_project +
        "</JobID><CutUNC>" +
        "\\\\icaproject.icaimballaggi.it\\jacopoos\\files\\173\\production\\" +
        this.prod_files[0] +
        "</CutUNC><Amount>" +
        this.prod_data.resa +
        "</Amount></Task>";
      var filename =
        this.prod_data.ref +
        "__" +
        this.prod_data.id_project +
        "__icaprogest" +
        ".xml";

      try {
        fs.writeFileSync(
          "\\\\esko-ws\\Dati Lavori\\in\\C44\\" + filename,
          xml_string,
          "utf-8"
        );
      } catch (e) {
        console.log("Failed to save the file !");
      }
    },

    formatDate: function (date) {
      var month = date.split("-")[1];
      var day = date.split("-")[2];
      var year = date.split("-")[0];
      return [day, month, year].join("-");
    },

    delegateProject: function () {
      const delegateForm = new FormData();

      delegateForm.append("delegate", this.prod_data.operator);
      delegateForm.append("type", "production");

      axios
        .post("delegate/" + this.prod_data.id_project, delegateForm)
        .then((response) => { });
    },

    getOther: function () {
      axios.get("to-prod/" + this.$route.params.id).then((response) => {
        this.prod_files = response.data.files;

        try {
          this.production_time = response.data.produzione;
        } catch { }
        try {
          this.start_time_produzione =
            response.data.produzione[response.data.produzione.length - 1].slice(
              -1
            )[0].start_time;
          this.end_time_produzione =
            response.data.produzione[response.data.produzione.length - 1].slice(
              -1
            )[0].end_time;
        } catch { }
      });
    },

    deleteProject: function () {
      this.$confirm(
        "Sei sicuro di voler cancellare la seguente produzione?"
      ).then((res) => {
        if (res) {
          axios
            .post("prod-data-p/delete/" + this.prod_data.id_project)
            .then((response) => {
              this.$router.push("/production").catch(() => { });
            });
        }
      });
    },
    setLoader: function () {
      const loader = document.querySelector("#loading");
      loader.style.display = "flex";
      if (this.uploading == 100) {
        setTimeout(function () {
          loader.style.display = "none";
        }, 1500);
      }
    },

    askConfirm: function (e) {
      if (this.edited) {
        var confirmationMessage = "Si";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage;
      }
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getDate() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getFullYear();
      var h = today.getHours();
      var m = today.getMinutes();
      if (h < 10) {
        h = "0" + h;
      }
      if (m < 10) {
        m = "0" + m;
      }

      const time = h + ":" + m;

      const dateTime = date + " " + time;
      this.timestamp = dateTime;
      this.date = date;
      this.time = time;
    },

    downloadFile(filename, type, index) {
      const fname = new FormData();
      fname.append("filename", filename);
      fname.append("type", type);
      fname.append("to", "production");
      axios
        .get(
          "download-production-file/" + this.prod_data.id_project + "/" + index,
          { responseType: "blob" }
        )
        .then((response) => {
          this.displayMessage(response.data.msg, "success");
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = filename.replace(/_/g, " ");
          link.click();
        });
    },

    goBack: function () {
      this.$router.push("/production").catch(() => { });
    },

    displayMessage: function (text, type) {
      this.response_type = type;
      this.success_text = text;
      this.success = true;
      setTimeout(() => (this.success = false), 1800);
    },

    generatePreviews: function () {
      try {
        var allegati = this.ica_files.concat(this.customer_files);
        var i;
        for (i = 0; i < allegati.length; i++) { }
      } catch { }
    },

    calcCopies(resa) {
      var result = this.prod_data.qty / resa;
      this.copies.qty = Math.ceil(result);
      this.copies.scarto = this.copies.qty - result;
    },
    confirmProduction: function (get) {
      get = this.current_prod_operation;
      console.log(get);

      const production_form = new FormData();
      this.getNow();

      if (get == "start") {
        this.start_time["produzione"] = this.timestamp;
        console.log(this.start_time.produzione);

        production_form.append("start", this.start_time["produzione"]);
        production_form.append("operator", this.prod_data.operator);
        production_form.append("requested_qty", this.prod_data.qty);
        production_form.append("resa", this.prod_data.resa);

        //this.createXml() DA INSERIRE PER LA 4.0 COMMENTA LA PARTE SOTTOSTANTE

        axios
          .post("task/produzione/" + this.prod_data.id_project, production_form)
          .then((response) => {
            this.prod_data.status = response.data.status;
            this.delegateProject();
            this.getOther();
            this.dialog_production = false;
          });
      } else {
        this.end_time["produzione"] = this.timestamp;
        production_form.append("end", this.end_time["produzione"]);
        production_form.append("produced", this.produced_qty);
        axios
          .post(
            "task/produzione" + "/" + this.prod_data.id_project,
            production_form
          )

          .then((response) => {
            this.prod_data.status = response.data.status;
            this.getOther();
            this.dialog_qty_produced = false;

            axios
              .post(
                "reset-operator/" + this.prod_data.id_project,
                production_form
              )

              .then((response) => {
                this.prod_data.operator = "";
                if (
                  this.production_time[this.production_time.length - 1].slice(
                    -1
                  )[0].remaining == 0
                ) {
                }
              });
          });
      }
    },

    Task: function (action) {
      if (this.prod_data.operator == "") {
        this.dialog_production = true;
        console.log("------------------");
        console.log(this.current_prod_operation);
      } else {
        this.dialog_qty_produced = true;
      }

      this.current_prod_operation = action;
    },

    deleteprodfiles: function (number) {
      axios
        .post("delete-prod-file/" + this.prod_data.id_project + "/" + number)
        .then((response) => {
          this.displayMessage(response.data.msg, "success");
          this.getOther();
        });
    },

    Completed: function () {
      axios
        .post("completed-prod/" + this.prod_data.id_project)
        .then((response) => {
          this.prod_data.status = "completato";
          this.$store.dispatch("notificate", {
            text: response.data.msg.text,
            type: response.data.msg.type,
          });
        });
    },
  },
};
</script>

<style scoped>
.actions>button {
  margin: 5px;
}

.force-h {
  height: 100% !important;
}

.production-allegato {
  min-height: 200px;
}

.mg-5 {
  margin: 2px;
}

.nomg {
  margin: 0;
}

.block-answer {
  width: 100%;
  display: flex;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0efeb;
  align-items: center;
  border-radius: 200px;
  justify-content: space-around;
}

.block-answer-sent {
  width: 100%;
  min-height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(219, 241, 241);
  align-items: center;
  border-radius: 10px;
  justify-content: space-around;
  padding: 10px;
}

.answer {
  padding: 10px;
  width: 80%;
  margin-right: 50px;
  height: 100%;
}

.answer-sent {
  width: 100%;
  margin-right: 50px;
  height: 100%;
}

.center {
  text-align: center;
}

.center_y {
  font-size: 14px;
  display: flex;
  align-items: center;
}

#files-2 {
  margin-top: 10px;
}

.upload-icon {
  text-align: center;
}

.msg-text {
  width: 100%;
  white-space: nowrap;
}

.fix {
  width: auto;
  padding-bottom: 20px;
  position: sticky;
  top: 0;
  margin: -20px;
  text-align: center;
  padding-top: 20px;
  margin-bottom: 30px;
  background-color: rgb(47, 47, 47);
  color: white;
}

.card-cont {
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
}

.time-content {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}

.time-title {
  padding: 0 !important;
}

.loading {
  z-index: 3000;
}

#loading {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.212);
}

.shape {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.246);
}

.limit-height {
  max-height: 20%;
}

.content-row {
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  flex-wrap: wrap;
}

.uploader::-webkit-scrollbar {
  width: 5px;
  z-index: 2000;
}

.uploader {
  -ms-overflow-style: 5px;
  /* IE and Edge */
  scrollbar-width: 5px;
  /* Firefox */
}

::-webkit-scrollbar {
  width: 2px;
  z-index: 4000;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.action-file {
  cursor: pointer;
}

.nopd {
  padding: 0px !important;
}

.allegato-row {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.185);
  background-color: white;
  flex: none;
}

.right {
  text-align: right;
}

.uploader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px;
}

#upload-false_ica-file,
#upload-false_customer-file,
#upload-false_prod-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.form-ica {
  margin-right: 10px;
  margin-left: 10px;
  height: 70%;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
}

.input-ica {
  border: 1px solid rgb(204, 204, 204);
  border-radius: 20px;
  background-color: #f0efeb;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.allegato-title {
  height: 20%;
  padding: 0px !important;
}

.form-allegato {
  height: 100%;
  padding: 0;
}

.files {
  height: 50%;
  padding: 15px;
}

.onsuccess,
.testolo {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.v-tabs {
  height: 10%;
}

.v-item-group {
  height: 90%;
}

.v-item-group>>>.v-window__container,
.v-item-group>>>.v-window-item {
  height: 100%;
}

.tab-content {
  height: 90%;
}

.new-customer {
  cursor: pointer;
}

.new-customer:hover {
  background-color: #f0efeb;
  border-radius: 4px;
}

.overlay-lock {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.664);
  display: flex !important;
  justify-content: center;
  align-items: center;
  color: white;
}

.hide {
  display: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.input-block {
  border: 1px rgb(190, 190, 190) solid;
  width: 100%;
  border-radius: 4px;
}


.full-height {
  height: 20%;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}

.elevation-1 {
  width: 100%;
  box-sizing: border-box;
}

.fixed-actions {
  position: fixed;
  bottom: 40px;
  right: 50%;
}

.generic-info {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.chat-data {
  width: 50%;
  flex-basis: auto !important;
  height: 100%;
}

.data-info {
  width: 50%;
  flex-basis: auto !important;
  box-sizing: border-box;
  height: 100%;
}

p {
  padding: 0;
  margin: 0;
}

label {
  margin-right: 5px;
}

.actions {
  position: fixed;
  width: 20%;
  height: 75px;
  left: 40%;
  bottom: 5px;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  align-items: center;
  z-index: 2000;
  justify-content: center;
}

.block-action {
  width: 33.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 5px;
}

.icon-action {
  height: 100%;
  width: 100%;
}

.smaller {
  height: 40px !important;
  width: 40px !important;
}

.icon {
  width: 50px;
  height: 50px;
}

.action-tooltip {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  padding: 8px;
  background-color: rgba(47, 47, 47, 0.767);
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
  transform: translateY(-150%);
  color: white !important;
}

.block-action:hover .action-tooltip {
  visibility: visible;
}

.chat {
  border: 1px solid rgb(204, 204, 204);
  height: 100%;
  border-radius: 20px;
  background-color: #f0efeb;
  display: flex;
}

.chat-view {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  border-radius: 0px 20px 20px 0px;
}

.chat-view::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.chat-view-ciko {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: scroll;
  border-radius: 20px 20px 0px 0px;
}

.chat-view-ciko::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chat-view-ciko {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.msg-time {
  margin-top: 20px;
  font-size: 12px;
  float: right;
  margin-bottom: 0 !important;
}

.chat-bar {
  height: 100%;
  box-sizing: border-box;
  padding: 5px 10px 5px 10px;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  width: 50%;
}

.send-msg {
  width: 10%;
  height: 100% !important;
  min-width: 50px !important;
}

#send {
  width: 100%;
  min-height: 100%;
  overflow-y: scroll;
  padding: 10px;
}

.block-msg {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 10px;
  white-space: pre-line;
}

.msg {
  background-color: white;
  padding: 21px 20px 5px 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  margin-bottom: 10px;
}

.viewer {
  float: right !important;
  background-color: rgb(255, 132, 110) !important;
  border-radius: 15px 0px 15px 15px !important;
}

.system {
  float: none !important;
  background-color: transparent !important;
  text-align: center;
  box-shadow: none;
}

.userlogo {
  text-transform: capitalize;
  color: rgb(240, 90, 97);
}

.file-preview {
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  transform: translateY(-80%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}

.file-preview-up {
  z-index: 250;
  visibility: hidden;
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  background-color: white;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.226);
  z-index: 5;
  margin-left: 93%;
  transform: translateY(-80%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 4px;
}

.allegato-row:hover .file-preview {
  visibility: visible;
}

.allegato-row:hover .file-preview-up {
  visibility: visible;
}

.dl-time {
  background: white;
  z-index: 2000;
}

.isediting {
  position: fixed;
  top: 12%;
  right: 2%;
}

.v-application .primary--text,
.blue--text.text--darken-1 {
  color: rgb(17, 121, 123) !important;
}

.mg-10 {
  margin-top: 10px;
}
</style>
